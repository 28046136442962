<template lang="pug">
  div
    figure.m-0.containerImage.mx-auto.block
      img(
        :src="urlData"
        alt="imageUser"
        ref="fileImage"
        v-show="!cropper"
      ).object-cover.w-full.h-full.p-16.imageModal#generalImage
    .flex.justify-between.fixed.orientationFooterImage(v-if="!modeCropper")
      button(@click="handleCropper").mt-4.mx-auto.ArrowBlack.rounded-bd-complete.w-10.h-10.flex.items-center.justify-center.border-none.outline-none
        span.textSize.material-icons-outlined.text-white crop
      button(
        @click="handleRotate"
      ).mt-4.mx-auto.ArrowBlack.rounded-bd-complete.w-10.h-10.flex.items-center.justify-center.border-none.outline-none
        span.textSize.material-icons-outlined.text-white crop_rotate
      button(
        @click="handleCreateImageHere"
      ).mt-4.mx-auto.ArrowBlack.rounded-bd-complete.w-10.h-10.flex.items-center.justify-center.border-none.outline-none
        span.textSize.material-icons-outlined.text-white check_circle
    .flex.justify-center(v-else)
      button(
        @click="cancelCropper"
      ).mt-4.ArrowBlack.rounded-bd-complete.w-10.h-10.flex.items-center.justify-center.border-none.outline-none
        span.textSize.material-icons-outlined.text-white close
      button(
        @click="handleCropperImage"
      ).mt-4.ml-10.ArrowBlack.rounded-bd-complete.w-10.h-10.flex.items-center.justify-center.border-none.outline-none
        span.textSize.material-icons-outlined.text-white check_circle
</template>

<script>
import Cropper from "cropperjs";

export default {
  name: "ImgCropper",
  data() {
    return {
      modeCropper: false,
      cropper: null,
      widthBox: 150,
      heightBox: 150,
      rotateDeg: 0,
    };
  },
  props: [
    "closeFullscreen",
    "openCropper",
    "fullscreenOpened",
    "handleCreateImage",
    "rotate",
    "urlData",
  ],
  mounted() {
    this.initialCropper();
  },
  methods: {
    async handleRotate() {
      await new Promise((resolve) => this.rotate(this.cropper, resolve));
      this.rotateDeg = this.cropper.getImageData().rotate;
    },
    clearCropper() {
      this.cropper.destroy();
      this.cropper = null;
    },
    initialCropper() {
      this.cropper = new Cropper(this.$refs.fileImage, {
        autoCrop: false,
        viewMode: 2,
        dragMode: "move",
        background: false,
        cropBoxMovable: true,
        cropBoxResizable: true,
        crop(event) {
          this.widthBox = event.detail.x;
          this.heightBox = event.detail.y;
        },
      });
    },
    handleCreateImageHere() {
      if (this.cropper) {
        const newUrl = this.cropper.getCroppedCanvas().toDataURL("image/jpeg");
        this.openCropper(newUrl);
      }
      this.handleCreateImage();
    },
    handleCropper() {
      this.modeCropper = true;
      const newUrl = this.cropper.getCroppedCanvas().toDataURL("image/jpeg");

      this.openCropper(newUrl);
      const timer = setTimeout(() => {
        this.clearCropper();
        this.cropper = new Cropper(this.$refs.fileImage, {
          autoCrop: true,
          viewMode: 2,
          dragMode: "move",
          background: false,
          cropBoxMovable: true,
          cropBoxResizable: true,
          crop(event) {
            this.widthBox = event.detail.x;
            this.heightBox = event.detail.y;
          },
        });
        clearTimeout(timer);
      }, 0);
      // console.log("Hello");
      // this.cropper.rotate(this.rotateDeg);
        // .toBlob((blob) => {
        //   console.log(blob);
        //   //emit
        // }, "image/jpeg");
      // this.openCropper();
      // this.handleCreateImage();
    },
    cancelCropper() {
      this.modeCropper = false;
      this.clearCropper();
      this.initialCropper();
    },
    handleCropperImage() {
      const newUrl = this.cropper.getCroppedCanvas().toDataURL("image/jpeg");

      this.openCropper(newUrl);
      const timer = setTimeout(() => {
        this.cancelCropper();
        clearTimeout(timer);
      }, 0);
    },
  },
}
</script>

<style scoped>
.containerImage {
  height: 100%;
  width: 100%;
  max-height: 450px;
  max-width: 600px;
  min-height: 450px;
}

.cropper-point {
  height: 20px;
  width: 20px;
}

.textSize {
  font-size: 30px;
}

.orientationFooterImage {
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 600px;
  z-index: 999999999999;
}
</style>
